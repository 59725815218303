import React, { useEffect, useState } from "react";
import logo from "../assets/biglogo.png";
import { Button, Checkbox, Form, Input } from "antd";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
import { api } from "../globals/baseUrl";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setLoginData, setToken } from "../redux/userSlice";
import { NavLink, useNavigate } from "react-router-dom";
import Register from "../components/Register";
import {
  showFailedToastAlert, 
  showSuccessfulToastAlert,
} from "../toastAlert.js/toastalert";
import { baseUrl } from "../api/url";

const Login = () => {
  const navigate = useNavigate();
  const [register, setRegister] = useState(false);
  const [Check, setCheck] = useState(false);
  const [login, setLogin] = useState({
    email: "",
    password: "",
    remember: false,
  });

  useEffect(() => {
  document.title = register ?  "Register | SmsPortal" : "Login | SmsPortal" ; 
  }, [register]);

  const dispatch = useDispatch();

  const handleChange = (e: any) => {
    const value = e.target.value;

    setLogin({ ...login, [e.target.name]: value });
  };

  const LoginForm = async (e: any) => {
    e.preventDefault();
    // console.log(login);
    // Logging in
    var data = JSON.stringify({
      email: login?.email,
      password: login?.password,
      remember: login?.remember,
    });

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      // url: `${baseUrl}api/v1/login`,
      url: `${baseUrl}/auth/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(function (response) {
        // console.log(response.data);
        const data = response?.data;
        if (data?.status === "success") {
          console.log(data);
          dispatch(setLoginData(data));
          dispatch(setToken(data?.data?.api_token));
          localStorage.setItem("token", data?.data?.api_token);
          navigate("/dashboard");
          window.location.reload();
          // }
        } else {
          toast.error("Credentials do not Match. Please try Again!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        showFailedToastAlert(error?.response?.data?.message);
      });
  };

  return (
    <div className=" bg-[white]">
      <div className="grid md:grid-cols-2 grid-cols-1 h-screen  md:py-[134px] pt-[40px]">
        <div className="flex items-center justify-center border-r-[1px] bg-white">
          <img
            src={logo}
            alt=""
            className="md:w-[246px] w-[180px] md:h-[100.55px]"
          />
        </div>
        {!register ? (
          <div className="  flex md:items-center items-start md:justify-center justify-start flex-col  px-[40px] lg:px-[62px] xl:px-[100px]   2xl:px-[186px]  ">
            <div className="w-full">
              <h2 className="text-[#0063A5] text-[22px] leading-[28.13px] font-normal text-center">
                Sign In
              </h2>
              <p className=" mt-[41px] text-[14px] leading-[16.41px] text-[#545454] w-full  font-normal text-center">
                Please sign-in to your account and start the adventure
              </p>
              <form action="" className="w-full" onSubmit={LoginForm}>
                <div className="w-full mt-[31px]">
                  <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                    Email
                  </p>
                  <input
                    type="email"
                    placeholder="Email address"
                    name="email"
                    className="w-full outline-none border bg-white border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <div className="flex items-center justify-between mt-[24px] mb-[5px]">
                    <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                      Password
                    </p>
                    <p className="text-[#0072B5] text-[13px] leading-[15.23px] font-normal cursor-pointer">
                      Forgot Password?
                    </p>
                  </div>
                  <input
                    type="password"
                    placeholder="password"
                    name="password"
                    className="w-full outline-none border bg-white border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
                    onChange={handleChange}
                  />
                </div>
                <div className="flex items-center gap-[8px] mt-[24px]">
                  <input
                    type="checkbox"
                    id=""
                    className="w-[16px] h-[16px] bg-white cursor-pointer border border-[#808080] outline-none"
                    onChange={(e) =>
                      setLogin({ ...login, remember: e?.target?.checked })
                    }
                  />
                  <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                    Remember Me
                  </p>
                </div>
                <button
                  type="submit"
                  className="btn w-full text-[14px] leading-[16.41px] font-normal text-[#F9F9F9] py-[14px] pl-[133px] pr-[134px] mt-[24px]"
                >
                  Login
                </button>
              </form>
              <div className=" mt-[41px] text-[14px] flex gap-2 justify-center leading-[16.41px] text-[#545454] w-full  font-normal">
                <span>New on Platform?</span>
                <div
                  className="text-blue-500 hover:cursor-pointer"
                  onClick={() => setRegister(true)}
                >
                  Register
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Register setRegister={setRegister} />
        )}
      </div>
    </div>
  );
};

export default Login;
