import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  form: {}
};

const registerSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setNewRegisterData: (state, action) => {
      state.form = action.payload;
      console.log("Form Payload From Registration Slice", action.payload);
    },   
    resetRegisterForm: (state) => {
      state.form = initialState.form;
    },
     
  },
});

export const { setNewRegisterData } =
  registerSlice.actions;

export default registerSlice.reducer;
