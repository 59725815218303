import { Select } from "antd";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const AccountInformation = ({
  setRegister,
  setAccount,
  setPersonal,
  setRegisterForm,
}) => {
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    language: "English",
  });

  const handleSelectorChange = (value: string) => {
    setFormValues((prev) => ({
      ...prev,
      language: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {
      email: "",
      password: "",
      confirmPassword: "",
    };

    // Email validation
    if (!formValues.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = "Email address is invalid";
    }

    // Password validation
    if (!formValues.password) {
      newErrors.password = "Password is required";
    } else if (formValues.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }

    // Confirm password validation
    if (formValues.confirmPassword !== formValues.password) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      setRegisterForm((prev) => ({
        ...prev,
        email: formValues.email,
        password: formValues.password,
        password_confirmation: formValues.confirmPassword,
      }));

      setAccount(false);
      setPersonal(true);
    }
  };

  return (
    <div>
      <p className="mt-[41px] text-[22px] leading-[16.41px] text-[#545454] w-full font-normal">
        Account Information
      </p>
      <p className="mt-[20px] text-[14px] leading-[16.41px] text-[#545454] w-full font-normal">
        Fill the below form to create a new account
      </p>
      <form action="" className="w-full" onSubmit={handleSubmit}>
        <div className="w-full mt-[31px]">
          <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
            Email
          </p>
          <input
            type="email"
            placeholder="Email address"
            name="email"
            className="w-full outline-none border bg-white border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
            onChange={(event) => {
              setFormValues((prev) => ({
                ...prev,
                email: event.target.value,
              }));
              setErrors((prev) => ({ ...prev, email: "" })); // Clear error on change
            }}
          />
          {errors.email && <p className="text-red-500 text-[12px]">{errors.email}</p>}
        </div>

        <div className="flex gap-5">
          <div className="w-1/2">
            <div className="flex items-center justify-between mt-[24px] mb-[5px]">
              <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                Password
              </p>
            </div>
            <input
              type="password"
              placeholder="Password"
              name="password"
              className="w-full outline-none bg-white border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
              onChange={(event) => {
                setFormValues((prev) => ({
                  ...prev,
                  password: event.target.value,
                }));
                setErrors((prev) => ({ ...prev, password: "" })); // Clear error on change
              }}
            />
            {errors.password && <p className="text-red-500 text-[12px]">{errors.password}</p>}
          </div>

          <div className="w-1/2">
            <div className="flex items-center justify-between mt-[24px] mb-[5px]">
              <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                Confirm Password
              </p>
            </div>
            <input
              type="password"
              placeholder="Confirm Password"
              name="password_confirmation"
              className="w-full outline-none bg-white border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
              onChange={(event) => {
                setFormValues((prev) => ({
                  ...prev, 
                  confirmPassword: event.target.value,
                }));
                setErrors((prev) => ({ ...prev, confirmPassword: "" })); // Clear error on change
              }}
            />
            {errors.confirmPassword && <p className="text-red-500 text-[12px]">{errors.confirmPassword}</p>}
          </div>
        </div>

        

        <div className="flex items-center gap-[8px] mt-[24px]">
          <input
            type="checkbox"
            id=""
            className="w-[16px] h-[16px] cursor-pointer border border-[#808080] outline-none"
          />
          <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
            Remember Me
          </p>
        </div>

        <div className="flex gap-5 justify-between">
          <NavLink to="/">
            <div className="w-[200px]">
              <button
                type="button"
                className="btn w-full text-[14px] leading-[16.41px] font-normal text-[#F9F9F9] py-[14px] px-[14px] mt-[24px]"
                onClick={() => setRegister(false)}
              >
                Back
              </button>
            </div>
          </NavLink>
          <div className="w-[200px]">
            <button
              type="submit"
              className="btn w-full text-[14px] leading-[16.41px] font-normal text-[#F9F9F9] py-[14px] px-[14px] mt-[24px]"
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AccountInformation;
