import { combineReducers } from "redux";
import smsTempleteReducer from "./reducer/smsTemplate";
import blockListReducer from "./reducer/blockListReducer";
import contactGroupReducer from "./reducer/contactGroupReducer";
import getSenderId from "./reducer/senderIdReducer";
import getNumbers from "./reducer/numberReducer";
import userSlice from "./userSlice";
import dashboardSlice from "./dashboardSlice";
import sendingSlice from "./sendingSlice";
import sideNavSlice from "./sideNavSlice/SideNavSlice";
import switchViewSlice from "./switchViewSlice";
import dropDownSlice from "./dropdown/dropDownSlice";
import viewPageSlice from "./viewPageSlice";
import registerFormSlice from "./registerFormSlice";

export const reducers: any = combineReducers({
  smsTempleteReducer,
  blockListReducer,
  contactGroupReducer,
  getSenderId,
  getNumbers,
  userSlice,
  registerFormSlice,
  dashboardSlice,
  sendingSlice,
  sideNavSlice,
  switchViewSlice,
  dropDownSlice,
  viewPageSlice,
});
