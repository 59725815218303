import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import verifyPayment from "../api/ProcessPayment/VerifyKhaltiPayment";
import { register } from "../api/User/Register";

const PaymentSuccessAndRegister = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true); // State to handle loading
  const [error, setError] = useState(null);     // State to handle errors 
  const [countdown, setCountdown] = useState(30); // 30-second countdown
   

  const transactionId = queryParams.get("transaction_id");
  const paymentId = queryParams.get("pidx");
  const registrationData = localStorage.getItem("registrationData");

  useEffect(() => {
    document.title = "Payment Verification - Registering the User";
  }, []);

  useEffect(() => {
    const fetchPaymentVerification = async () => {
      try {
        if (!paymentId) {
          throw new Error("Payment ID (pidx) is missing from the URL.");
        }

        if (!registrationData) {
          throw new Error(
            "Registration Failed. Please contact customer support."
          );
        }

        // Parse registration data safely
        let userRegistrationData;
        try {
          userRegistrationData = JSON.parse(registrationData);
          userRegistrationData.pidx = paymentId;
        } catch (parseError) {
          throw new Error(
            "Registration Failed. Please contact customer support."
          );
        }

        // Verify the payment
        const paymentResponse = await verifyPayment(paymentId);
        setData(paymentResponse);

        if (paymentResponse?.status !== "success") {
          throw new Error(
            "Payment verification failed."
          );
        }              
        const registrationResponse = await register(userRegistrationData);
        console.log("Registration response:", registrationResponse);

        navigate("/login");
      } catch (err) {
        console.error(err);
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
        // Clear registration data after attempt
        localStorage.removeItem("registrationData");
      }
    };

    fetchPaymentVerification();
  }, [paymentId, registrationData]);

  useEffect(() => {
    let timer;
    if (!loading && !error && transactionId) {
      // Start countdown only on successful registration
      timer = setInterval(() => {
        setCountdown((prevCount) => {
          if (prevCount <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer); // Cleanup on unmount
  }, [loading, error, transactionId]);

  useEffect(() => {
    if (countdown === 0) {
      // After countdown, navigate to a different page or perform another action
      navigate("/login"); // Example: Redirect to login page
      toast.info("Redirecting to login page.");
    }
  }, [countdown, navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="flex flex-col items-center">
          <div className="loader mb-4"></div> {/* Replace with a spinner component if available */}
          <p className="text-xl">Processing your registration...</p>
        </div>
      </div>
    );
  }

  if (error) {
    toast.error(error); // Display an error toast notification

    setTimeout(()=>{  
      navigate('/login')
    }, 30000)

    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline"> {error}</span>          
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <h1 className="text-3xl font-semibold mb-4">
        Registration Successful!
      </h1>
      <p className="mb-2">
        Your transaction ID: <span className="font-mono">{transactionId}</span>
      </p>
      <p className="mb-6">
        Please copy your transaction ID for future reference.
      </p>
      <button
        onClick={() => {
          navigator.clipboard.writeText(transactionId);
          toast.success("Transaction ID copied to clipboard!");
        }}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Copy Transaction ID
      </button>
      <p className="mt-4 text-gray-600">
        Redirecting in {countdown} second{countdown !== 1 && "s"}...
      </p>
      <p className="mt-2 text-center text-gray-700">
        If you encounter any issues with your registration, please{" "}
        <button
          onClick={() => navigate("/contact")}
          className="text-blue-500 underline"
        >
          contact customer support
        </button>
        .
      </p>
    </div>
  );
};

export default PaymentSuccessAndRegister;
