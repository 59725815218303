import axios from "axios";
import { getToken } from "../../utils/tokenUtils";
import { baseUrl } from "../url";

export const register = async (payload: any) => {
  const token = getToken();
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/auth/register`,
    headers: { 
      Authorization: `Bearer ${token}`,
      "Content-Type": "Application/json",
    },
    data: payload,
  };
  return await axios.request(config);
};
