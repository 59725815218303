import axios from "axios";
import { baseUrl } from "../url";

export const getPlans = async () => {
  const modifiedBaseUrl = baseUrl.replace("/v3", "");
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${modifiedBaseUrl}/http/getPlans`,
  };
  return await axios.request(config);
};
 