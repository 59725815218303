// src/api/ProcessPayment/RequestKhaltiPaymentURL.ts
import axios, { AxiosResponse } from 'axios';
import { baseUrl } from '../url';


// Define the PaymentData type properly
interface PaymentData {
  name: string;
  amount: number;
  email: string;
  phone: string;
} 

interface KhaltiPaymentResponse {
  payment_url: string;  
}

export default async function RequestKhaltiPaymentURL(data: PaymentData): Promise<KhaltiPaymentResponse> {

  const { name, amount, email, phone } = data;


  const payload = {
    amount: amount,
    customer_info: {
      name: name,
      email: email,
      phone: phone,
    },  
    product_details: [
      {
      identity: "1234567890",
      name: "Khalti logo",      
    }
  ],
  };
  

  console.log(payload)

  try {
    const response: AxiosResponse<KhaltiPaymentResponse> = await axios.request({
        method: 'POST',
        url: `${baseUrl}/auth/payment/initiate`,
        headers: {
          "Content-Type": "application/json", 
          
        },
        data: payload, // Use 'data' instead of 'json'
        maxBodyLength: Infinity, // Optional: adjust as needed
      });
    return response.data; // Returns the response data
  } catch (error: any) {
    console.log("full error", error)
    // Enhanced error handling
    if (error.response) {
      // Server responded with a status other than 2xx
      console.error('Error initiating payment:', error.response.data);
      throw new Error(error.response.data.message || 'Failed to initiate payment.');
    } else if (error.request) {
      // No response received from server
      console.error('No response received:', error.request);
      throw new Error('No response from payment server. Please try again later.');
    } else {
      // Error setting up the request
      console.error('Error setting up request:', error.message);
      throw new Error('Error initiating payment. Please try again.');
    }
  }
}
