import { Select } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";

const countryOptions = [{ value: "Nepal" }, { value: "India" }, { value: "USA" }]; // Extend this array as needed

const PersonalInformation = ({ setAccount, setBilling, setPersonal, setRegisterForm }) => {
  const handleChange = (field) => (event) => {
    setRegisterForm((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleCountryChange = (value) => {
    setRegisterForm((prev) => ({
      ...prev,
      country: value,
    }));
  };

  return (
    <div>
      <p className="mt-[41px] text-[22px] leading-[16.41px] text-[#545454] w-full font-normal">Personal Information</p>
      <p className="mt-[20px] text-[14px] leading-[16.41px] text-[#545454] w-full font-normal">
        Fill the below form to create a new account
      </p>
      <form onSubmit={(e) => e.preventDefault()}> {/* Prevent default form submission */}
        <div className="flex gap-5">
          <div className="w-1/2">
            <label className="flex items-center justify-between mt-[24px] mb-[5px]">
              <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">First Name</p>
            </label>
            <input
              type="text"
              placeholder="First Name"
              name="firstName"
              className="w-full outline-none bg-white border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
              onChange={handleChange('first_name')}
            />
          </div>

          <div className="w-1/2">
            <label className="flex items-center justify-between mt-[24px] mb-[5px]">
              <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">Last Name</p>
            </label>
            <input
              type="text"
              placeholder="Last Name"
              name="lastName"
              className="w-full outline-none bg-white border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
              onChange={handleChange('last_name')}
            />
          </div>
        </div>

        <div className="flex gap-5">
          <div className="w-1/2">
            <label className="flex items-center justify-between mt-[24px] mb-[5px]">
              <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">Phone</p>
            </label>
            <input
              type="tel"
              placeholder="Phone"
              name="phone"
              minLength={10}
              className="w-full outline-none bg-white border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
              onChange={handleChange('phone')}
            />
          </div>

          <div className="w-1/2">
            <label className="flex items-center justify-between mt-[24px] mb-[5px]">
              <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">Post Code</p>
            </label>
            <input
              type="number"
              placeholder="Post Code"
              name="postCode"
              className="w-full outline-none bg-white border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
              onChange={handleChange('post_code')}
            />
          </div>
        </div>

        <div className="w-full">
          <label className="flex items-center justify-between mt-[24px] mb-[5px]">
            <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">Address</p>
          </label>
          <input
            type="text"
            placeholder="Address"
            name="address"
            className="w-full outline-none bg-white border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
            onChange={handleChange('address')}
          />
        </div>

        <div className="w-full">
          <label className="flex items-center justify-between mt-[24px] mb-[5px]">
            <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">Company</p>
          </label>
          <input
            type="text"
            placeholder="Company"
            name="company"
            className="w-full outline-none bg-white border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
            onChange={handleChange('company')}
          />
        </div>

        <div className="flex gap-5">
          <div className="w-1/2">
            <label className="flex items-center justify-between mt-[24px] mb-[5px]">
              <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">City</p>
            </label>
            <input
              type="text"
              placeholder="City"
              name="city"
              className="w-full outline-none bg-white border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
              onChange={handleChange('city')}
            />
          </div>

          <div className="w-1/2">
            <label className="flex items-center justify-between mt-[24px] mb-[5px]">
              <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">Country</p>
            </label>
            <Select
              defaultValue="Nepal"
              style={{ width: "100%" }}
              className="mt-[8px]"
              options={countryOptions}
              onChange={handleCountryChange}
            />
          </div>
        </div>
      </form>

      <div className="flex gap-5 justify-between">
        <NavLink to="/">
          <div className="w-[200px]">
            <button 
              type="button" // Changed from submit to button
              className="btn w-full text-[14px] leading-[16.41px] font-normal text-[#F9F9F9] py-[14px] px-[14px] mt-[24px]"
              onClick={() => {
                setPersonal(false);
                setAccount(true);
              }}
            >
              Back
            </button>
          </div>
        </NavLink>
        <div className="w-[200px]">
          <button
            type="submit" // Set to submit for the form
            className="btn w-full text-[14px] leading-[16.41px] font-normal text-[#F9F9F9] py-[14px] px-[14px] mt-[24px]"
            onClick={() => {setBilling(true);setPersonal(false);}} // Trigger the next step
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
