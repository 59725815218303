import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { getPlans } from "../../api/Plans/FetchPlans";
import { register } from "../../api/User/Register"; 
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../toastAlert.js/toastalert";
import { sendOtp } from "../../api/otp/SendOtp";
import { AxiosError, AxiosResponse } from "axios";
import RequestKhaltiPaymentURL from "../../api/ProcessPayment/RequestKhaltiPaymentURL";
import { useDispatch } from "react-redux";
import { setNewRegisterData } from "../../redux/registerFormSlice";
import { setTimeout } from "timers/promises";

type Plan = {
  id: number;
  name: string;
  price: number;
  billing_cycle: string;
};



const BillingInformation = ({
  setAccount,
  setBilling,
  setPersonal,
  setRegisterForm,
  registerForm,
}) => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({
    price: 0,
  });

  // Dispatch Initialization
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const paymentOptions = [
    { id: "offline_payment", name: "Offline Payment", icon: "credit-card" },
    { id: "khalti", name: "Khalti", icon: "Khalti" },
    // Add more payment options as needed
  ];

  const handleOptionSelect = (option) => {
    
    setSelectedPaymentOption(option.id);
    setRegisterForm((prev) => ({
      ...prev,
      payment_methods: option.id,
    }));
  };

  const handlePlanClick = (plan) => {
    setSelectedPlan(plan);
    setSelectedPlanId(plan.id);
    setRegisterForm((prev) => ({
      ...prev,
      plans: plan.id,
    }));
  };

  useEffect(() => {
    fetchPlan();
  }, []);
  

  const fetchPlan = async () => {
    try {
      const planData = await getPlans();
      if (planData.status === 200) {
        console.log(planData.data)
        setPlans(planData.data?.data?.plans);
      } else {
        showFailedToastAlert("Failed to fetch plans.");
      }
    } catch (error) {
      console.log(error) 
      showFailedToastAlert("An error occurred while fetching plans.");
    }
  };
  
  const handleSubmit = async () => {
    try {
      setLoading(true);      
  
      console.log(registerForm);
  
      // Validate selected plan and payment option
      if (!selectedPlan) {
        showFailedToastAlert("Please select a plan.");
        setLoading(false);
        return;
      }
  
      if (!selectedPaymentOption) {
        showFailedToastAlert("Please select a payment method.");
        setLoading(false);
        return;
      }

      
      // Convert amount to paisa (1 rupee = 100 paisa)
      const amountInPaisa = Math.round(selectedPlan.price * 100 );
      registerForm.amount = amountInPaisa;

      localStorage.setItem('registrationData', JSON.stringify(registerForm));
      
      console.log("Set Item Into Local Storage")      ;

      const formData = {
        name: `${registerForm.first_name} ${registerForm.last_name}`, // Added space for readability
        amount: amountInPaisa, 
        email: registerForm.email, 
        phone: registerForm.phone
      };
  
      console.log('Form Data:', formData); // Debugging
  
      const data = await RequestKhaltiPaymentURL(formData);
  
      console.log('Payment Response:', data);
  
      if (data.payment_url) {
        window.location.href = data.payment_url;
      } else {
        showFailedToastAlert("Invalid payment response from backend.");
      }
  
    } catch (err: any) {
      console.error("Payment initiation error:", err);
      showFailedToastAlert(err.response?.data?.message || "An error occurred during payment.");
      setLoading(false)
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div>
      <div>
        {plans && plans.length > 0 && (
          <>          
            <p className=" mt-[41px] text-[22px] leading-[16.41px] text-[#545454] w-full  font-normal">
              Select Plans
            </p>
            <p className=" mt-[20px] text-[14px] leading-[16.41px] text-[#545454] w-full  font-normal">
              Select plan as per your retirement
            </p>
            {/* plans  */}
            <div className="flex gap-3">
              {plans.map((plan) => {
                return (
                  <div
                    key={plan.id}
                    className={`${
                      selectedPlanId === plan.id
                        ? "bg-blue-100" // Add a different style for selected plan
                        : "bg-gray-100"
                    } inline-block my-5 p-5 border-2 rounded-lg border-orange-500`}
                    onClick={() => handlePlanClick(plan)}
                  >
                    <p>{plan.name}</p>
                    <span className="text-[var(--primary-color)]">
                      <p>
                        Rs.{plan.price}
                        <sub>/ {plan.billing_cycle}</sub>
                      </p>
                    </span>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>

      <p className=" mt-[41px] text-[22px] leading-[16.41px] text-[#545454] w-full  font-normal">
        Payment Options
      </p>
      <p className=" mt-[20px] text-[14px] leading-[16.41px] text-[#545454] w-full  font-normal">
        Be sure to click on correct payment options
      </p>
      {/* payment options */}
      <div className="bg-white rounded-lg p-6 shadow-md w-80">
        <ul className="space-y-2">
          {paymentOptions.map((option) => (
            <li
              key={option.id}
              className={`flex items-center space-x-3 p-2 rounded-md hover:bg-gray-100 cursor-pointer ${
                selectedPaymentOption === option.id ? "bg-blue-100" : ""
              }`}
              onClick={() => handleOptionSelect(option)}
            >
              <span
                className={`text-lg ${
                  selectedPaymentOption === option.id ? "text-blue-500" : ""
                }`}
              >
                <i className={`fab fa-${option.icon}`}></i>
              </span>
              <span
                className={`text-base font-medium ${
                  selectedPaymentOption === option.id ? "text-blue-500" : ""
                }`}
              >
                {option.name}
              </span>
            </li>
          ))}
        </ul>
      </div>
      {/* Buttons */}
      <div className="flex gap-5 justify-between">
        <NavLink to="/">
          <div className="w-[200px]">
            <button
              type="submit"
              className="btn w-full text-[14px] leading-[16.41px] font-normal text-[#F9F9F9] py-[14px] px-[14px] mt-[24px]"
              onClick={() => {
                setPersonal(true);
                setAccount(false);
                setBilling(false);
              }}
            >
              back
            </button>
          </div>
        </NavLink>
        <div className="w-[200px]">
        <button
            type="submit"
            className="btn w-full text-[14px] leading-[16.41px] font-normal text-[#F9F9F9] py-[14px] px-[14px] mt-[24px]"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>

        </div>
      </div>
    </div>
  );
};

export default BillingInformation;
