import React, { useRef, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import { getToken } from "../../../utils/tokenUtils";
import { baseUrl } from "../../../api/url";
import { showFailedToastAlert } from "../../../toastAlert.js/toastalert";

type Props = {};

const SmsPieChart: React.FC<Props> = () => {
  const [chartReport, setChartReport] = useState<any>();
  const chartRef = useRef(null);
  const token = getToken();

  useEffect(() => {
    const fetchChartData = async () => {
      if (chartRef.current) {
        try {
          const config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${baseUrl}/users/dashboard`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }; 

          const response = await axios.request(config);
          // console.log(response.data, "pie chart data");
          const data = response.data;
          if (data?.status === "success") {
            console.log(data);
            setChartReport(data?.data);
          } else {
            showFailedToastAlert(`Getting pie chart error ${data?.message}`);
          }
        } catch (error: any) {
          showFailedToastAlert(`Getting pie chart error ${error?.message}`);
          console.log(error?.message);
        }
      }
    };

    fetchChartData();
  }, [chartRef, token]); // Ensure token is included as a dependency

  return (
    <Chart
      options={{
        labels: ["Not Delivered", "Delivered"],
        colors: ["#DC3545", "#F1602B"],
        dataLabels: {
          enabled: true,
          formatter: function (val: any) {
            return `${val.toFixed(2)} %`;
          },
        },
      }}
      series={[
        chartReport?.sms_history?.delivered_count || 1,
        chartReport?.sms_history?.undelivered_count || 1,
      ]}
      type={"pie"}
      height={300}
      ref={chartRef}
      className="w-[350px]"
    />
  );
};

export default SmsPieChart;
